:root {
  --grid-margin: 2.4rem;
  --grid-gutter: 1.2rem;
  --grid-min-width: ~"calc(124rem - (var(--grid-margin)*2))";
  --grid-max-width: 128rem;
  --sidemenu-width: 0rem;

  --grid-columns-1: ~"calc(min(var(--grid-max-width), ((100vw - (var(--grid-margin) * 2)) / 12) - (var(--grid-gutter) * 11 / 12)))";
  --grid-columns-2: ~"calc(var(--grid-columns-1)*2 + var(--grid-gutter) * 1)";
  --grid-columns-3: ~"calc(var(--grid-columns-1)*3 + var(--grid-gutter) * 2)";
  --grid-columns-4: ~"calc(var(--grid-columns-1)*4 + var(--grid-gutter) * 3)";
  --grid-columns-5: ~"calc(var(--grid-columns-1)*5 + var(--grid-gutter) * 4)";
  --grid-columns-6: ~"calc(var(--grid-columns-1)*6 + var(--grid-gutter) * 5)";
  --grid-columns-7: ~"calc(var(--grid-columns-1)*7 + var(--grid-gutter) * 6)";
  --grid-columns-8: ~"calc(var(--grid-columns-1)*8 + var(--grid-gutter) * 7)";
  --grid-columns-9: ~"calc(var(--grid-columns-1)*9 + var(--grid-gutter) * 8)";
  --grid-columns-10: ~"calc(var(--grid-columns-1)*10 + var(--grid-gutter) * 9)";
  --grid-columns-11: ~"calc(var(--grid-columns-1)*11 + var(--grid-gutter) * 10)";
  --grid-columns-12: ~"calc(var(--grid-columns-1)*12 + var(--grid-gutter) * 11)";
}

body.is-desktop-detected .lobby-page {
  --sidemenu-width: 24rem;

  /* Recalculate grid values based on the new --sidemenu-width */
  --grid-columns-1: ~"calc(min(var(--grid-max-width), (100vw - var(--sidemenu-width) - var(--grid-gutter)) - var(--grid-margin) * 2) / 12 - (var(--grid-gutter) * 11 / 12))";
  --grid-columns-2: ~"calc(var(--grid-columns-1)*2 + var(--grid-gutter) * 1)";
  --grid-columns-3: ~"calc(var(--grid-columns-1)*3 + var(--grid-gutter) * 2)";
  --grid-columns-4: ~"calc(var(--grid-columns-1)*4 + var(--grid-gutter) * 3)";
  --grid-columns-5: ~"calc(var(--grid-columns-1)*5 + var(--grid-gutter) * 4)";
  --grid-columns-6: ~"calc(var(--grid-columns-1)*6 + var(--grid-gutter) * 5)";
  --grid-columns-7: ~"calc(var(--grid-columns-1)*7 + var(--grid-gutter) * 6)";
  --grid-columns-8: ~"calc(var(--grid-columns-1)*8 + var(--grid-gutter) * 7)";
  --grid-columns-9: ~"calc(var(--grid-columns-1)*9 + var(--grid-gutter) * 8)";
  --grid-columns-10: ~"calc(var(--grid-columns-1)*10 + var(--grid-gutter) * 9)";
  --grid-columns-11: ~"calc(var(--grid-columns-1)*11 + var(--grid-gutter) * 10)";
  --grid-columns-12: ~"calc(var(--grid-columns-1)*12 + var(--grid-gutter) * 11)";
}

@media (min-width: 768px) {
  :root {
    --grid-gutter: 1.6rem;
    --grid-margin: 3.2rem;
  }
}

.page-area {
  padding-left: 0;
  padding-right: 0;
  font-variant: lining-nums;
}

.search-result-content::-webkit-scrollbar {
  width: 0.4rem;
}

.search-result-content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.5rem;
}

.search-result-content::-webkit-scrollbar-thumb {
  background: #C7CAD1;
  border-radius: 0.5rem;
}

body.no-scroll {
  overflow: hidden;
  width: 100%;

  &.is-desktop-detected {
    padding-right: 17px;
  }
}

.reality-check-notification-fallback {
  display: none;
}

/* Todo: Revisit this implementation - maybe lottie file should be changed */
#lottie-container:not(.playing):hover svg g g:nth-of-type(1) g path,
#lottie-container:not(.playing):focus svg g g:nth-of-type(1) g path {
  fill: #C4A76D;
}

#lottie-container:not(.playing) svg g g:nth-child(7) g {
  transform: scale(1.27);
  transition: transform 0.25s cubic-bezier(0.51, 0.62, 0.34, 1.07);
}

.search-result-content::-webkit-scrollbar {
  width: 0.4rem;
}

.search-result-content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.5rem;
}

.search-result-content::-webkit-scrollbar-thumb {
  background: #C7CAD1;
  border-radius: 0.5rem;
}

body {
  color: #333;
}

/* WinnersFeed module */
.cw-winnerslist-top {
  width: 25.6rem;
  height: 12.8rem;
  border-radius: 12.8rem 12.8rem 0 0;
  left: 50%;
  transform: translateX(-50%);
}

.cw-winnerslist-top--gold {
  background: url('data:image/svg+xml,%3Csvg width="256" height="173" viewBox="0 0 300 203" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M280.17 133C267.37 133 258 120.8 258 108C258 48.3532 209.647 0 150 0C90.3532 0 42 48.3532 42 108C42 120.8 32.63 133 19.8297 133C8.87804 133 0 141.878 0 152.83V168C0 187.33 15.6701 203 35 203H88.4365C95.0969 203 101.593 204.801 107.721 207.411C120.706 212.94 134.996 216 150 216C165.004 216 179.294 212.94 192.279 207.411C198.407 204.801 204.903 203 211.563 203H265C284.33 203 300 187.33 300 168V152.83C300 141.878 291.122 133 280.17 133Z" fill="%23A78952"/%3E%3C/svg%3E%0A') 50% 0 no-repeat;
}

.cw-winnerslist-top--purple {
  background: url('data:image/svg+xml,%3Csvg width="256" height="173" viewBox="0 0 300 203" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M280.17 133C267.37 133 258 120.8 258 108C258 48.3532 209.647 0 150 0C90.3532 0 42 48.3532 42 108C42 120.8 32.63 133 19.8297 133C8.87804 133 0 141.878 0 152.83V168C0 187.33 15.6701 203 35 203H88.4365C95.0969 203 101.593 204.801 107.721 207.411C120.706 212.94 134.996 216 150 216C165.004 216 179.294 212.94 192.279 207.411C198.407 204.801 204.903 203 211.563 203H265C284.33 203 300 187.33 300 168V152.83C300 141.878 291.122 133 280.17 133Z" fill="%23581231"/%3E%3C/svg%3E%0A') 50% 0 no-repeat;
}

/* BlackjackSeatSelector */
.cw-blackjack-seats h1 {
  font-size: 2.4rem;
  line-height: 2.6rem;
}

.cw-blackjack-semicircle--outer {
  height: 24.5rem;
  width: 32.7rem;
  margin-left: -16.35rem;
  border-radius: 0 0 32.7rem 32.7rem;
}

.cw-blackjack-semicircle--inner {
  width: 26.2rem;
  height: 13.1rem;
  margin-left: -13.1rem;
  border-radius: 2.3rem 2.3rem 45.5rem 45.5rem;
}

.cw-blackjack-seaticon {
  animation: cw-pulse 1.5s infinite linear;
  padding: 0.8rem;
}

.cw-blackjack-seaticon--active {
  --cw-pulse-size: 0.5rem;
}

.cw-blackjack-seaticon--inactive {
  --cw-pulse-size: 0;
}

@keyframes cw-pulse {
  0% {
    box-shadow: none;
  }

  50%, 100% {
    box-shadow: 0 0 0 var(--cw-pulse-size) rgb(213,191,133);
  }
}

.cw-blackjack-seatlogo {
  top: 2.4rem;
  margin-left: -6rem;
  justify-items: center;
}

.cw-blackjack-seatlogo--cards {
  width: 12rem;
  height: 2.7rem;
}

.cw-blackjack-seatlogo--text {
  width: 11.7rem;
  height: 2.3rem;
}

.cw-blackjack-seat-1 {
  top: 5.2rem;
  left: 0;
}

.cw-blackjack-seat-2 {
  top: 10rem;
  left: -0.9rem;
}

.cw-blackjack-seat-3 {
  top: 13.2rem;
  left: 0.1rem;
}

.cw-blackjack-seat-4 {
  top: 14.7rem;
  left: 2.4rem;
}

.cw-blackjack-seat-5 {
  top: 13.2rem;
  left: 4.5rem;
}

.cw-blackjack-seat-6 {
  top: 9.9rem;
  left: 5.7rem;
}

.cw-blackjack-seat-7 {
  top: 5.2rem;
  left: 4.9rem;
}

@media (min-width: 768px) {
  .cw-blackjack-seats h1 {
    font-size: 3.4rem;
    line-height: 3.6rem;
  }

  .cw-blackjack-semicircle--outer {
    height: 35rem;
    width: 57.8rem;
    margin-left: -28.9rem;
    border-radius: 0 0 57.8rem 57.8rem;
  }

  .cw-blackjack-semicircle--inner {
    width: 46rem;
    height: 23rem;
    margin-left: -23rem;
    border-radius: 4rem 4rem 80rem 80rem;
  }

  .cw-blackjack-seaticon--active {
    --cw-pulse-size: 1rem;
  }

  .cw-blackjack-seaticon {
    padding: 1.6rem;
  }

  .cw-blackjack-seatlogo {
    top: 4.8rem;
    margin-left: -10.5rem;
  }

  .cw-blackjack-seatlogo--cards {
    width: 21.1rem;
    height: 4.8rem;
  }

  .cw-blackjack-seatlogo--text {
    width: 20.5rem;
    height: 4rem;
  }

  .cw-blackjack-seat-1 {
    top: 9.2rem;
    left: 0.1rem;
  }

  .cw-blackjack-seat-2 {
    top: 17.5rem;
    left: -0.3rem;
  }

  .cw-blackjack-seat-3 {
    top: 23.6rem;
    left: 1.5rem;
  }

  .cw-blackjack-seat-4 {
    top: 25.8rem;
    left: 6.4rem;
  }

  .cw-blackjack-seat-5 {
    top: 23.6rem;
    left: 11.3rem;
  }

  .cw-blackjack-seat-6 {
    top: 17.5rem;
    left: 13rem;
  }

  .cw-blackjack-seat-7 {
    top: 9.2rem;
    left: 13rem;
  }
}

@media (min-width: 1024px) {
  .cw-blackjack-seats h1 {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}

/* Tile badge */
.cw-badge {
  top: -0.3rem;
  left: -0.3rem;
}

.cw-badge-tl-6 {
  top: 0.6rem;
  left: 0.6rem;
}

.is-desktop-detected .cw-badge-tl-10 {
  top: 1rem;
  left: 1rem;
}

.cw-badge-tl-10 {
  top: -0.2rem;
  left: -0.2rem;
}

.cw-badge--small p {
  font-size: 0.7rem;
  top: 1.5rem;
  left: -0.5rem;
}

.cw-badge--large p {
  font-size: 1rem;
  top: 2.4rem;
  left: -0.8rem;
}
